$primary-color: #fd6144;
$secondary-color: #0097a7;
$black-color: #2e3239;
$light-grey-color: #f0f0f0;
$grey-color: #aaa;
$border-color: #ddd;
$background-color: #ffffff;

$primary-orange-disabled: rgba(253, 97, 68, 0.4);
$primary-orange: #fd6144;
$primary-maroon: #b52c63;
$primary-cyan: #0097a7;
$primary-teal: #00796b;
$primary-blue: #1976d2;
$primary-purple: #512da8;
