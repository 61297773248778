@import '../../theme/base.scss';

.textbox {
    border: 0;
    letter-spacing: 0.3px;
    width: 100%;
    color: $primary-color;
    resize: none;
    transition: border ease 0.3s;
    border: 1px solid transparent;
    border-radius: 3px;
    padding: $grid-xxs 0px;
    background-color: $background-color;
}

.textbox::placeholder {
    color: $grey-color;
}

.textbox:focus {
    outline: none;
    background-color: $light-grey-color;
}

.textbox:hover {
    border: 1px solid $secondary-color;
}