@import '../../theme/base.scss';

.settingsToggler {
  margin-top: $spacing-sm;
  background-color: $black-color;
  border-radius: $grid-xs;
  color: $light-grey-color;
  display: inherit;
}

.settingsDiv {
  display: inline-block;
  border-right: 1px solid $grey-color;
  padding: $spacing-sm;
}

.settingsDiv:nth-last-child(1) {
  border-right: 0;
}

.selected {
  color: #ffffff;
}

.popoverSettings {
  margin: 0 auto;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.colorSettings {
  border: 1px solid #ffffff;
  margin-top: $spacing-xxs;
}

.typoSettings {
  border: 1px solid #ffffff;
  padding: 4px;
  border-radius: 5px;
  text-align: center;
  min-width: 115px;
}

.templateImgLi {
  display: inline;
}

.templateImgDiv {
  display: inline-block;
  cursor: pointer;
  text-align: center;
}


.templateImgGrid {
  justify-content: center;
}

.templateImg {
  width: 150px;
  margin-top: $spacing-sm;
  display: block;
}

.templateImgSelected {
  border: 1px solid $primary-orange;
}

.colorOption {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  cursor: pointer;
}

.colorOrange {
  background-color: $primary-orange;
}

.colorMaroon {
  background-color: $primary-maroon;
}

.colorCyan {
  background-color: $primary-cyan;
}

.colorTeal {
  background-color: $primary-teal;
}

.colorBlue {
  background-color: $primary-blue;
}

.colorPurple {
  background-color: $primary-purple;
}

.colorBlack {
  background-color: $black-color;
}

.MuiPopover-root {
  margin-top: 10px;
  border: 1px solid black;
}

.dividerMargin {
  margin: $spacing-sm 0 !important;
}

.checkboxDisabled {
  color: $primary-orange-disabled !important;
}
