@import '../../theme//base.scss';

.container {
    display: flex;
    margin: $grid-xs 0px;
    padding: $grid-xs 0px;
    border: 2px solid transparent;
    transition: border ease 0.5s;
}

.container:hover {
    border: 2px solid $grey-color;
    border-style: dashed;
}

.radioContainer {
    width: 5%;
    padding: $grid-xxs;
}

.bullet {
    font-size: $font-lg !important;
}

.description {
    margin-top: $grid-sm;
}

.datetimeAndLocation {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.textboxesContainer {
    width: 100%;
    padding: 0px $grid-xs;
}

.datetime {
    width: 70%;
    text-align: right;
}

.cancelIcon {
    color: red;
    opacity: 0;
}

.container:hover .cancelIcon {
    opacity: 1;
}
