@import '../../theme/base.scss';

.container {
    margin: $grid-sm 0px;
}

.itemKey {
    font-weight: $semi-bold;
    margin: $grid-xxs 0px;
}
