@import '../../theme/base.scss';

.footer {
  border-top: 1px solid $border-color;
  text-align: center;
  padding: $spacing-lg;
  margin-top: $spacing-lg;
}

.footerImg {
  max-width: 200px;
}
