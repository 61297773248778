@import '../../theme/base.scss';

.container {
  padding: $grid-lg;
}

.headline {
  margin: $grid-lg 0px;
}

.divider {
  display: flex;
  width: 100%;
}

.left {
  width: 70%;
  padding-right: $grid-lg;
  align-self: stretch;
}

.right {
  width: 30%;
  align-self: stretch;
}
