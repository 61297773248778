@import '../../theme/base.scss';

.container {
    font-family: $font-opensans;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.templateContainer {
    min-height: 1028px;
    width: 900px;
    z-index: 1;
    box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.3);
    margin: $grid-md;
    background: $background-color;
}

.leftIcon {
    margin-right: $spacing-sm;
}

.marginRight {
    margin-right: $grid-xs !important;
}