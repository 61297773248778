@import url('https://fonts.googleapis.com/css?family=Noto+Serif+SC:400,600,700,900|Open+Sans:400,600,700,800&display=swap');

$font-opensans: 'Open Sans';
$font-notoserif: 'Noto Serif SC';

$font-xs: 8px;
$font-sm: 12px;
$font-md: 16px;
$font-lg: 22px;
$font-xl: 30px;
$font-xxl: 42px;

$semi-bold: 600;
$bold: 700;
$bolder: 800;
$boldest: 900;