@import '../../theme/base.scss';

.title {
    margin: $grid-sm 0px;
    font-weight: $bold;
    font-size: $font-lg;
    color: $black-color;
}

.description {
    margin: $grid-sm 0px;
}

.addIconContainer {
    text-align: center;
    padding: $grid-xxs;

}

.addIcon {
    position: absolute;
    font-size: $font-xl !important;
    opacity: 0;
    color: $primary-color;
    cursor: pointer;
}

.container:hover .addIcon {
    opacity: 1;
}
