@import 'colors.scss';
@import 'typo.scss';

.styleOrange {
  p, input, svg {
    color: $primary-orange;
  }
}

.styleMaroon {
  p, input, svg {
    color: $primary-maroon;
  }
}

.styleCyan {
  p, input, svg {
    color: $primary-cyan;
  }
}

.styleTeal {
  p, input, svg {
    color: $primary-teal;
  }
}

.styleBlue {
  p, input, svg {
    color: $primary-blue;
  }
}

.stylePurple {
  p, input, svg {
    color: $primary-purple;
  }
}

.styleBlack {
  p, input, svg {
    color: $black-color;
  }
}

.styleTypographyOpenSans {
  p, input, textarea, div {
    font-family: $font-opensans;
  }
}

.styleTypographyMonospace {
  p, input, textarea, div {
    font-family: monospace;
  }
}
